import React from "react"
import { Link } from "gatsby"
import { Navbar, Nav } from "react-bootstrap"

// Icons
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';


const NavbarMain = ({ siteTitle }, { pageInfo },) => {
  console.log(pageInfo)
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar" className="bg-primary shadow" collapseOnSelect>
        {/* <Container> */}
        <Link to="/" className="">
          <Navbar.Brand as="span" className="text-uppercase">
            <span className="font-weight-normal">Austin</span> <span className="font-weight-bold">Moto</span>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto text-uppercase" activeKey={pageInfo && pageInfo.pageName}>
            <Link to="/#section-maintenance" className="">
              <Nav.Link as="span" eventKey="#section-maintenance" className="">
                Maintenance
              </Nav.Link>
            </Link>
            <Link to="/#section-parts" className="">
              <Nav.Link as="span" eventKey="#section-parts">
                Parts
              </Nav.Link>
            </Link>
            <Link to="/#section-restoration" className="">
              <Nav.Link as="span" eventKey="#section-restoration">
                Restoration
              </Nav.Link>
            </Link>
            <Link to="/#section-custom-builds" className="">
              <Nav.Link as="span" eventKey="#section-custom-builds">
                Custom Builds
              </Nav.Link>
            </Link>
            <Link to="/#section-sales" className="">
              <Nav.Link as="span" eventKey="#section-sales">
                Sales
              </Nav.Link>
            </Link>
            <a 
              href="tel:5122914604"
              target="_blank"
              className="nav-link font-weight-bold text-center"
            >
              (512) 291-4604
            </a>
            <Link to="/#section-location">
              <Nav.Link as="span" eventKey="#section-location" className="d-none">
                <FaMapMarkerAlt />
              </Nav.Link>
            </Link>
          </Nav>
          <nav class="d-block d-lg-none text-center">
            <a href="https://www.google.com/maps/place/Austin+Moto+Classics/@30.220356,-97.764143,13z/data=!4m5!3m4!1s0x0:0x99589848109be6c8!8m2!3d30.2203562!4d-97.7641433?hl=en" target="_blank" rel="noreferrer">
              4201 S Congress Ave #316
              <br />
              Austin, TX 78745
            </a>
            <br></br>
            <a
              href="mailto:austinmotoclassics@yahoo.com"
              target="_blank"
              className="large"
              title="Email"
            >
              <FaEnvelope />
            </a>
            &nbsp;&nbsp;
            <a
              href="https://www.instagram.com/austinmoto"
              target="_blank"
              className="large"
              title="Instagram"
            >
              <FaInstagram />
            </a>
            <br></br>
          </nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  )
}

export default NavbarMain
