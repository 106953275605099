/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Navbar from "./navBar"

// Icons
import { FaInstagram } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';

const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Navbar pageInfo={pageInfo} siteTitle={data.site.siteMetadata.title} />
        <main className="layout--main">{children}</main>
        <footer class="">
          <Container fluid>
            <Row className="align-items-center py-2 text-center">
              <Col md="4">
                <a href="tel:5122914604" target="_blank">
                  (512) 291-4604
                </a>
              </Col>
              <Col md="4" className="">
                <a
                  href="https://www.google.com/maps/place/Austin+Moto+Classics/@30.220356,-97.764143,13z/data=!4m5!3m4!1s0x0:0x99589848109be6c8!8m2!3d30.2203562!4d-97.7641433?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  4201 S Congress Ave #316
                  <br />
                  Austin, TX 78745
                </a>
              </Col>
              <Col md="4">
                <a
                  href="mailto:austinmotoclassics@yahoo.com"
                  target="_blank"
                  className="large"
                  title="Email"
                >
                  <FaEnvelope />
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://www.instagram.com/austinmoto"
                  target="_blank"
                  className="large"
                  title="Instagram"
                >
                  <FaInstagram />
                </a>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    )}
  />
)

export default Layout
